<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-btn x-small fab absolute top right color="primary" class="mt-7" @click="showImportDialog=true">
        <v-icon>mdi-application-import</v-icon>
      </v-btn>

      <v-col cols="6">
        <v-card>
          <v-card-title>Contact</v-card-title>
          <v-card-subtitle>The person</v-card-subtitle>
          <ContactEditor ref="contactEditor" v-model="contact"/> <!-- call fetchContact to get the contents -->
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-title>Client</v-card-title>
          <v-card-subtitle>The company</v-card-subtitle>
          <ClientEditor ref="clientEditor" v-model="client"/>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn color="primary" @click="save">Save</v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="showImportDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Import from contact form
        </v-card-title>

        <v-card-text>
          <ClientImporter @import="clientImported"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ContactEditor from "../contacts/ContactEditor"
import ClientEditor from "../clients/ClientEditor"
import ClientImporter from "@/components/clients/ClientImporter"

export default {
  name: "CreateProspect",
  props: {
    "UUID": {type: String}
  },
  permissions: ["Modify Client", "Modify Contact", ...ClientImporter.permissions, ...ContactEditor.permissions, ...ClientEditor.permissions],
  components: {
    ClientImporter, ContactEditor, ClientEditor
  },
  methods: {
    initialize() {
      this.client = {
        name: "",
        address: "",
        city: "",
        region: "",
        postCode: "",
        country: "",
        postalAddress: "",
        postalCity: "",
        postalRegion: "",
        postalPostCode: "",
        postalCountry: "",
        postalPhone: "",
        website: "",
        isProspect: "",
        referralSource: "",
      }
      this.contact = {
        name: "",
        phone: "",
        mobile: "",
        email: "",
        position: "",
      }
    },
    save() {
      const client = this.$refs.clientEditor.getClient()
      const contact = this.$refs.contactEditor.getContact()
      const compiledClient = {
        Client: {
          Name: client.name,
          ReferralSource: client.referralSource,
          IsProspect: client.isProspect,
          Address: client.address,
          City: client.city,
          Region: client.region,
          PostCode: client.postCode,
          Country: client.country,
          PostalAddress: client.postalAddress,
          PostalCity: client.postalCity,
          PostalRegion: client.postalRegion,
          PostalPostCode: client.postalPostCode,
          PostalCountry: client.postalCountry,
          WebSite: client.website,
          Contacts: {
            Contact: {
              Name: contact.name,
              Phone: contact.phone,
              Mobile: contact.mobile,
              Email: contact.email,
              Position: contact.position
            }
          }
        }
      }
      this.$WfmApi.post("client.api/add", compiledClient).then((res) => {
        this.$refs.clientEditor.saveCustomFields(res.Client.UUID)
        this.$emit("saved", res.Client)
      })
    },
    clientImported(data) {
      this.client = {
        name: data.company,
        city: data.location,
        region: data.location,
        country: data.country,
        isProspect: true,
        referralSource: data.contactMethod,
      }
      this.contact = {
        name: data.name,
        phone: data.phone,
        mobile: "",
        email: data.email,
        position: data.position
      }
      this.showImportDialog = false
    }
  },
  mounted() {
    this.initialize()
  },
  data() {
    return {
      title: "Client",
      showTitle: true,
      editable: false,
      isReady: true,
      settings: {},
      showImportDialog: false,
      client: {},
      contact: {}
    }
  },
}

</script>

<style scoped>
</style>