<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable">
    <v-form ref="form" v-model="valid" lazy-validation :isReady="isReady">
      <v-row>
        <v-col>
          <JobsFilter v-model="jobs" @filterChanged="filteredJobIds = $event" state="open"></JobsFilter>

          <InvoicePeriod v-model="invoicePeriod" @input="invoicePeriodChanged"></InvoicePeriod>

          <v-data-table dense :headers="headers" :items="tableData" :items-per-page="100" fixed-header height="100vh">
            <template v-slot:item.Name="{item}">
              {{ item.Name }}
              <v-chip v-if="item.is_complete" color="primary" small>Complete</v-chip>
            </template>

            <template v-slot:item.invoiced="{item}">
              <v-checkbox v-model="item.is_invoiced" @click="toggleIsInvoiced(item)"/>
            </template>

            <template v-slot:item.wip_writeoff="{item}">
              <v-checkbox v-model="item.wip_writeoff" @click="toggleWriteOff(item)"/>
            </template>

            <template v-slot:item.invoiced_amount="{item}">
              {{ item.invoiced_amount ? $formatCurrency(item.invoiced_amount) : '' }}
            </template>

            <template v-slot:item.comments="{item}">
              {{ item.comments }}
              <v-btn icon x-small @click="showCommentsDialog(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.actions="{item}">
              <div class="action-button-wrapper">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small dense class="action-button" color="primary" :to="{ path: '/jobs/review', query: { JobId: item.ID }}" v-bind="attrs" v-on="on">
                      <v-icon small>mdi-account-hard-hat</v-icon>
                    </v-btn>
                  </template>
                  <span>Job Review</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small dense class="action-button" color="primary" :to="{ path: '/job-capacity', query: { JobId: item.ID }}" v-bind="attrs" v-on="on">
                      <v-icon small>mdi-human-capacity-decrease</v-icon>
                    </v-btn>
                  </template>
                  <span>Job Capacity Planner</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small dense class="action-button" color="primary" :to="{ path: '/jobs/invoicing', query: { JobId: item.ID }}" v-bind="attrs" v-on="on">
                      <v-icon small>mdi-currency-usd</v-icon>
                    </v-btn>
                  </template>
                  <span>Invoicing</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small dense class="action-button" color="primary" v-bind="attrs" v-on="on" :href="item.WebUrl" target="_blank">
                      <v-icon>mdi-alpha-f</v-icon>
                    </v-btn>
                  </template>
                  <span>WorkflowMax Finance</span>
                </v-tooltip>

              </div>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="commentsDialog" width="auto">
      <v-card>
        <v-card-title>
          Edit Comment
        </v-card-title>
        <v-card-text>
          <v-textarea v-model="selectedJob.comments" filled></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn @click="saveSelectedJob" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import JobsFilter from "@/components/jobs/JobsFilter.vue"
import InvoicePeriod from "@/components/invoicing/InvoicePeriod.vue"
import {toLocalString} from "@/lib/dateTimeUtilities"

export default {
  name: "JobsAuditReport",
  permissions: ["Read Job"],
  components: {
    InvoicePeriod,
    JobsFilter,
    BfwComponent,
  },
  methods: {
    toggleIsInvoiced(item) {
      this.selectedJob = item
      this.saveSelectedJob()
    },
    toggleWriteOff(item) {
      this.$BwfApi.post("job", {
        job_id: item.ID,
        wip_writeoff: item.wip_writeoff
      })
    },
    showCommentsDialog(item) {
      this.selectedJob = item
      this.commentsDialog = true
    },
    saveSelectedJob() {
      this.$BwfApi.post("invoice", {
        month_str: this.invoicePeriod,
        job_id: this.selectedJob.ID,
        is_invoiced: this.selectedJob.is_invoiced,
        comments: this.selectedJob.comments
      }).then(() => {
        this.selectedJob = {}
        this.commentsDialog = false
        this.$notify.toast("Saved")
      })
    },
    invoicePeriodChanged() {
      this.loadInvoiceData()
    },
    loadInvoiceData() {
      this.$BwfApi.get("invoice", {month_str: this.invoicePeriod})
          .then(response => {
            this.bwfInvoiceData = response
          })
    },
    findBwfJob(jobId) {
      for (const bwfJob of this.bwfJobs) {
        if (bwfJob.job_id === jobId) {
          return bwfJob
        }
      }
      return false
    },
    findBwfInvoiceMonth(jobId) {
      for (const bwfInfo of this.bwfInvoiceData) {
        if (bwfInfo.job_id === jobId) {
          return bwfInfo
        }
      }
      return false
    },
    reload() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
      const query = {"from": start, "to": end}

      this.$WfmApi.get("job.api/list", query).then(response => {
        this.jobs = response.Jobs.Job
        this.isReady = true
      })
      this.$BwfApi.get("jobs").then(response => {
        this.bwfJobs = response
      })
    }
  },
  mounted() {
    this.reload()
  },
  computed: {
    tableData: function () {
      const tableData = []

      for (const item of this.jobs) {
        if (this.filteredJobIds.includes(item.ID)) {
          const bwfJob = this.findBwfJob(item.ID)
          const bwfJobInvoiceMonth = this.findBwfInvoiceMonth(item.ID)
          tableData.push({
            ID: item.ID,
            UUID: item.UUID,
            Name: item.Name,
            client: item.Client.Name,
            StartDate: item.StartDate,
            DueDate: item.DueDate,
            WebUrl: item.WebURL.replace('/information', '/financials'),
            manager: item.Manager ? item.Manager.Name : "",
            capacity: bwfJob && bwfJob.capacity_updated ? bwfJob.capacity_updated.substring(0, 16) : "Never",
            invoice_reviewed: bwfJob && bwfJob.invoice_reviewed ? bwfJob.invoice_reviewed.substring(0, 16) : "Never",
            wip_writeoff: !!(bwfJob && bwfJob.wip_writeoff),
            invoiced_amount: bwfJobInvoiceMonth ? bwfJobInvoiceMonth.invoiced_amount : "",
            comments: (bwfJobInvoiceMonth && bwfJobInvoiceMonth.comments) ? bwfJobInvoiceMonth.comments : "",
            is_invoiced: !!(bwfJobInvoiceMonth && bwfJobInvoiceMonth.is_invoiced),
            is_complete: item.State === "Completed"
          })
        }
      }
      return tableData
    }
  },
  data() {
    return {
      title: "Jobs Overview",
      showTitle: false,
      editable: false,
      isReady: false,
      valid: true,
      invoicePeriod: null,
      filteredJobIds: [],
      jobs: [],
      bwfJobs: [],
      selectedJob: {},
      commentsDialog: false,
      bwfInvoiceData: [],
      headers: [
        {text: "", value: "actions"},
        {text: "Job ID", value: "ID"},
        {text: "Job", value: "Name"},
        {text: "Client", value: "client"},
        {text: "Manager", value: "manager"},
        {text: "Capacity Reviewed", value: "capacity"},
        {text: "Invoice Reviewed", value: "invoice_reviewed"},
        {text: "Pending Review / No Bill", value: "invoiced"},
        {text: "Write Off", value: "wip_writeoff"},
        {text: "Invoiced Amount", value: "invoiced_amount"},
        {text: "Comments", value: "comments"},
      ],
    }
  },
}

</script>

<style scoped>
td > div {
  display: inline-block;
}

.action-button {
  height: 25px !important;
  width: 25px !important;
  padding: 0;
  margin-right: 5px;
  min-width: auto !important;
}

.action-button-wrapper {
  width: 130px
}
</style>