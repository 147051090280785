<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable">
    <v-form ref="form" v-model="valid" lazy-validation v-if="isReady">
      <v-simple-table dense class="text-center">
        <template v-slot:default>

          <tr>
            <th>Start Date</th>
            <th>Original Due Date</th>
            <th>Expected Due Date</th>
            <th>Days Remaining</th>
          </tr>
          <tr>
            <td>
              <JobFieldStartDate :job="job"/>
            </td>
            <td>
              <CustomField type="job" :typeUUID="jobId" :fieldUUID="settings.originalDueDateUUID" @saved="originalDueDate = $event" :edit="true" :view="true"/>
            </td>
            <td>
              <JobFieldDueDate :job="job"/>
            </td>
            <td :class="{'red--text': daysRemainingActual<0}">{{ daysRemainingActual }}</td>

          </tr>

          <tr>
            <th class="pt-4">Budget</th>
            <th class="pt-4">Used</th>
            <th class="pt-4">Planned Work</th>
            <th class="pt-4"></th>
          </tr>
          <tr>
            <td>{{ hoursBudget | formatMinutes }}</td>
            <td>{{ hoursUsed | formatMinutes }}</td>
            <td>
              <div class="inline">
                {{ hoursRemaining | formatMinutes }}
              </div>
            </td>
            <td>
              <OneLineGraph :used="hoursUsed" :budget="hoursBudget"/>
            </td>
          </tr>
        </template>
      </v-simple-table>
    </v-form>
  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import OneLineGraph from "../toolkit/OneLineGraph"
import JobFieldDueDate from "./fields/JobFieldDueDate"
import JobFieldStartDate from "./fields/JobFieldStartDate"
import CustomField from "../toolkit/CustomField"

export default {
  name: "JobScheduleSummary",
  permissions: ["Read Job", ...JobFieldDueDate.permissions, ...JobFieldStartDate.permissions, ...CustomField.permissions],
  props: {jobId: {}, showTitle: {default: true}},
  components: {
    BfwComponent, OneLineGraph, JobFieldDueDate, JobFieldStartDate, CustomField
  },
  methods: {
    isHistorical(monthStr) {
      const parts = monthStr.split("-")
      const dataYear = parseInt(parts[0])
      const dataMonth = parseInt(parts[1])
      const now = new Date()
      const currentYear = now.getFullYear()
      const currentMonth = now.getMonth() + 1
      const c = currentYear * 12 + currentMonth
      const d = dataYear * 12 + dataMonth
      return d < c
    },
    loadPlanned() {
      this.$BwfApi.get("capacity-plan/job", {job_id: this.jobId}).then(response => {
        let remaining = 0
        for (const monthString in response) {
          if (!this.isHistorical(monthString)) {
            for (const serviceId in response[monthString]) {
              remaining += response[monthString][serviceId]
            }
          }
        }
        this.hoursRemaining = remaining*60 // assumes minutes. capacity planning uses hours
      })
    },
    processResponse(response) {
      this.job = response.Job
      let estimated = 0
      let actual = 0
      const tasks = (response.Job.Tasks) ? this.$ensureArray(response.Job.Tasks.Task) : []
      tasks.forEach(function (task) {
        estimated += parseInt(task.EstimatedMinutes)
        actual += parseInt(task.ActualMinutes)
      })

      this.hoursBudget = estimated
      this.hoursUsed = actual
      this.isReady = true
    },
    getBusinessDatesCount(startDate, dueDate, allowNegative = false) {
      let start = new Date(startDate.getTime())
      let end = new Date(dueDate.getTime())
      let multiplier = 1

      // if start < due then swap them around (if negative values are allowed)
      if (allowNegative && startDate.getTime() > dueDate.getTime()) {
        const temp = new Date(startDate.getTime())
        start = new Date(dueDate.getTime())
        end = temp
        multiplier = -1
      }

      let count = 0
      let curDate = start

      while (curDate <= end) {
        let dayOfWeek = curDate.getDay()
        if (!((dayOfWeek === 6) || (dayOfWeek === 0))) {
          count++
        }
        curDate.setDate(curDate.getDate() + 1)
      }
      return count * multiplier
    },
    getCustomFields() {
      this.$WfmApi.get("job.api/get/" + this.jobId + "/customfield", {})
          .then((response) => this.getCustomFieldsResponse(response))
    },
    getCustomFieldsResponse(response) {
      this.agreedDueDate = undefined
      if (response.CustomFields) {
        const customFields = this.$ensureArray(response.CustomFields.CustomField)
        customFields.forEach(field => {

          if (field.UUID === this.settings.originalDueDateUUID) {
            this.originalDueDate = new Date(field.Date)
          } else {
            this.originalDueDate = null
          }
        })
      }
    }
  },
  mounted() {

  },
  watch: {
    jobId: function () {
      if (this.jobId) {
        this.isReady = false
        this.$WfmApi.get("job.api/get/" + this.jobId).then(response => this.processResponse(response))
        this.getCustomFields()
        this.loadPlanned()
      } else {
        this.isReady = false
      }
    }
  },
  computed: {
    daysRemainingActual: function () {
      return this.getBusinessDatesCount(new Date(), new Date(this.job.DueDate), true)
    },
  },
  data() {
    return {
      title: "Schedule",
      editable: false,
      isReady: false,
      valid: true,
      job: {},
      settings: {
        originalDueDateUUID: "9c483d6b-3c50-44a4-9bfe-904880c11677",
      },
      hoursBudget: 0,
      hoursUsed: 0,
      originalDueDate: "",
      hoursRemaining: 0,
    }
  }
}

</script>

<style scoped>

.inline {
  display: inline;
}

</style>