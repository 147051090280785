<template>
  <div v-if="isReady && services.length > 0" class="wrapper" id="bar-scroll-wrapper">
    <div class="chart-area">
      <v-chart class="chart" :option="chartOptions" :style="{width:chartWidth+'px'}" :key="graphKey"/>
      <div class="add-button">
        <v-btn @click="addMonth" color="primary" class="btn" x-large fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <br/>
        <v-btn @click="removeMonth" class=" mt-5 btn" color="red" dark x-small fab>
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="scroll-controls">
      <div v-for="(detail,monthStr) in data" v-bind:key="monthStr" :id="monthStr"
           :class="{'month-column':true, 'month-column-disabled': isHistorical(monthStr)}" :style="{ 'width': barWidth + 'px' }">
        <div v-for="service in services" v-bind:key="service.service_id" class="scroll-control">
          <ScrollControl v-model="data[monthStr][service.service_id]" :max="serviceEstimates[service.service_id]"
                         :color="getColor(service.service_id)" @input="changed" ref="scrollControlRef"
                         :disabled="!$access.permissions.includes('Modify Capacity Plan')"/>
        </div>
      </div>
    </div>
    <div class="scroll-controls-totals">
      <div v-for="service in services" v-bind:key="service.service_id" class="scroll-controls-total">
        <span :style="{color:totalsColor( totals[service.service_id] ,serviceEstimates[service.service_id])}">
          {{ Math.round(totals[service.service_id]) }} / {{ serviceEstimates[service.service_id] }}
          </span> {{ serviceNames[service.service_id] }}
      </div>
      <div class="scroll-controls-grand-total">
        <span :style="{color:totalsColor(this.totalPlanned, this.totalEstimate)}">{{ totalPlanned }}/{{ totalEstimate }}</span> Total
      </div>

    </div>
    <p>The grey area is showing the actual hours spent.<br/>
      The service totals are made up of actual hours for past months plus planned hours going forward.</p>
  </div>
  <div v-else>
    <p>This job does not contain any services which can be planned.</p>
  </div>
</template>

<script>

import ScrollControl from "@/components/toolkit/ScrollControl"
import {use} from "echarts/core"
import {colors} from "@/lib/constants"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  MarkAreaComponent
} from "echarts/components"
import VChart from "vue-echarts"
import {defineComponent} from "vue"
import {formatMonth} from "@/lib/dateTimeUtilities"

use([
  CanvasRenderer,
  LineChart,
  LegendComponent,
  GridComponent,
  MarkLineComponent,
  MarkAreaComponent
])
export default defineComponent({
  name: "BarScroll",
  components: {
    ScrollControl,
    VChart
  },
  props: ["services", "value", "expectedDueDate", "originalDueDate", "timeUsedThisJob"],
  permissions: [],
  computed: {
    chartOptions: function () {
      const serviceData = {}
      for (const service of this.services) {
        serviceData[service.service_id] = []
      }

      for (const monthStr in this.data) {
        for (const service_id in this.data[monthStr]) {
          if (service_id in serviceData) {
            if (this.data[monthStr][service_id]["isHistorical"]) {
              serviceData[service_id].push(this.data[monthStr][service_id]["used"])
            } else {
              serviceData[service_id].push(this.data[monthStr][service_id]["planned"])
            }
          }
        }
      }

      const xAxis = []
      for (const monthStr of Object.keys(this.data)) {
        xAxis.push(formatMonth(monthStr))
      }

      const series = []

      for (const service of this.services) {
        series.push({
          name: service.name,
          type: "bar",
          itemStyle: {color: colors[service.color_idx]},
          data: serviceData[service.service_id],
          stack: "stack1"
        })
      }

      if (series.length > 0) {
        series[0].markLine = {
          silent: true,
          lineStyle: {width: 3, type: "solid"},
          symbol: "none",
          label: {show: false},
          data: [
            {xAxis: formatMonth(this.originalDueDate), lineStyle: {color: "#f87979"}, label: {formatter: "Agreed Due", show: true}},
            {xAxis: formatMonth(this.expectedDueDate), lineStyle: {color: "blue"}, label: {formatter: "Expected Due", show: true}}
          ]
        }

        let startMarkArea = ""
        let endMarkArea = ""
        for (const monthStr of Object.keys(this.data)) {
          if (this.isHistorical(monthStr)) {
            if (!startMarkArea) {
              startMarkArea = formatMonth(monthStr)
            }
            endMarkArea = formatMonth(monthStr)
          }
        }
        series[0].markArea = {
          silent: true,
          itemStyle: {
            color: "#eee",
            opacity: 0.5
          },
          data: [[
            {xAxis: startMarkArea},
            {xAxis: endMarkArea}
          ]]
        }
      }

      return {
        animation: false,
        grid: {
          left: 30,
          top: 50,
          right: 0,
          bottom: 50
        },
        legend: {},
        xAxis: {
          type: "category",
          data: xAxis,
          axisLabel: {
            interval: 0,
          }
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "#666"
            }
          }
        },
        series: series
      }
    },
    chartWidth() {
      return (this.barWidth * Object.keys(this.data).length) + 21
    },
    totalPlanned() {
      const total = Object.values(this.totals).reduce((a, b) => a + b, 0)
      return Math.round(total)
    },
    totalEstimate() {
      const estimate = Object.values(this.serviceEstimates).reduce((a, b) => a + b, 0)
      return Math.round(estimate)
    },
  },
  methods: {
    reset() {
      // reset all scroll controls
      this.$refs.scrollControlRef.forEach(item => {
        item.reset()
      })
    },
    isHistorical(monthStr) {
      const parts = monthStr.split("-")
      const dataYear = parseInt(parts[0])
      const dataMonth = parseInt(parts[1])
      const now = new Date()
      const currentYear = now.getFullYear()
      const currentMonth = now.getMonth() + 1
      const c = currentYear * 12 + currentMonth
      const d = dataYear * 12 + dataMonth
      return d < c
    },
    totalsColor(planned, estimated) {
      return planned > estimated ? "red" : "green"
    },
    addMonth() {
      this.$emit("changed", {month: 1})

      const monthStrings = Object.keys(this.data)
      const lastMonth = monthStrings[monthStrings.length - 1]
      const parts = lastMonth.split("-")
      let month = parseInt(parts[1])
      let year = parseInt(parts[0])
      month += 1
      if (month === 13) {
        month = 1
        year += 1
      }
      const newMonth = year.toString() + "-" + month.toString()

      const template = {}
      for (const service of this.services) {
        template[service.service_id] = {planned: 0, used: 0, isHistorical: this.isHistorical(newMonth)}
      }

      this.$set(this.data, newMonth, template)
      this.graphKey = Math.random() // force graph to redraw
      this.$nextTick(() => {
        document.getElementById("bar-scroll-wrapper").scrollLeft = 10000 // scroll all the way to the right
      })

    },
    removeMonth() {
      this.$emit("changed", {month: -1})
      const monthStrings = Object.keys(this.data)
      const lastMonth = monthStrings[monthStrings.length - 1]
      this.$delete(this.data, lastMonth)
      this.graphKey = Math.random() // force graph to redraw

    },
    changed() {
      this.updateTotals()
      const plannedOnly = {}
      for (const monthStr in this.data) {
        plannedOnly[monthStr] = {}
        const item = this.data[monthStr]
        for (const service_id in item) {
          plannedOnly[monthStr][service_id] = 0
          if (!item[service_id]["isHistorical"]) {
            plannedOnly[monthStr][service_id] = item[service_id]["planned"]
          }
        }
      }
      this.$emit("input", plannedOnly)
      this.$emit("changed")
    },
    updateTotals() {
      let totals = {}
      for (const service of this.services) {
        totals[service.service_id] = 0
      }
      for (const monthStr in this.data) {
        const item = this.data[monthStr]
        for (const service_id in item) {
          if (item[service_id]["isHistorical"]) {
            totals[service_id] += item[service_id]["used"]
          } else {
            totals[service_id] += item[service_id]["planned"]
          }
        }
      }
      this.totals = totals
    },
    getColor(service_id) {
      for (const service of this.services) {
        if (service.service_id === parseInt(service_id)) {
          return colors[service.color_idx]
        }
      }
      return colors[0]
    },
  },
  mounted() {
    const data = {}
    for (const monthStr in this.value) {
      let monthData = this.value[monthStr]
      for (const service of this.services) {
        const plannedValue = monthData[service.service_id] ? monthData[service.service_id] : 0

        let usedValue = 0
        if (this.isHistorical(monthStr)) { // replace planned value with actual value if time has passed
          if (monthStr in this.timeUsedThisJob && service.service_id in this.timeUsedThisJob[monthStr]) {
            usedValue = Math.round(10 * this.timeUsedThisJob[monthStr][service.service_id]) / 10
          }
        }
        data[monthStr] = data[monthStr] || {}
        data[monthStr][service.service_id] = data[monthStr][service.service_id] || {planned: 0, used: 0}
        data[monthStr][service.service_id]["planned"] = plannedValue
        data[monthStr][service.service_id]["used"] = usedValue
        data[monthStr][service.service_id]["isHistorical"] = this.isHistorical(monthStr)
      }
    }
    this.data = data

    this.serviceEstimates = {}
    for (const service of this.services) {
      this.serviceEstimates[service.service_id] = service.estimated
    }

    this.serviceNames = {}
    for (const service of this.services) {
      this.serviceNames[service.service_id] = service.name
    }
    this.updateTotals()
    this.isReady = true

    this.$nextTick(() => {
      // scroll so the current month is in view
      const now = new Date()
      const currentMonth = now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, "0")
      const el = document.getElementById(currentMonth)
      if (el) {
        document.getElementById("bar-scroll-wrapper").scrollLeft = el.offsetLeft
      }
    })
  },
  data() {
    return {
      isReady: false,
      barWidth: 80,
      data: {},
      serviceEstimates: {},
      serviceNames: {},
      totals: {},
      graphKey: Math.random(),
    }
  }
})

</script>

<style scoped>
.chart-area {

}

.month-column {
  display: inline-block;
  text-align: center;
  padding-top: 5px;
}

.month-column-disabled {
  background: #f6f6f6;
}

.scroll-control {
  padding: 5px 0;
}

.scroll-controls {
  padding-left: 25px;
  display: inline-block;
  vertical-align: top;
}

.scroll-controls-totals {
  display: inline-block;
  margin-top: 5px;
  position: absolute;
  background: white;
}

.scroll-controls-total {
  padding: 18px 0;
  vertical-align: top;
}

.scroll-controls-grand-total {
  padding: 18px 0 0 0;
  vertical-align: top;
  font-weight: bold;
}

.chart {
  height: 300px;
  display: inline-block;
}

.wrapper {
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
}

.add-button {
  margin-top: 80px;
  margin-left: 50px;
  display: inline-block;
  vertical-align: top;
  text-align: center;

}

.btn {
  /*width: 180px;*/
}
</style>