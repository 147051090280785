<template>
  <v-container fluid>
    <h1>Work Pipeline</h1>
    <EstimatedBilling></EstimatedBilling>
    <PipelineGraph></PipelineGraph>

  </v-container>
</template>

<script>


import PipelineGraph from "@/components/capacity/PipelineGraph.vue"
import EstimatedBilling from "@/components/invoicing/EstimatedBilling.vue"

export default {
  name: "ReportPipelinePage",
  permissions: [...PipelineGraph.permissions],
  components: {EstimatedBilling, PipelineGraph},
  data() {
    return {}
  },
  mounted() {

  },
}

</script>

<style scoped>

</style>