<template>
  <div>
    <div v-if="!quoteFound">
      <v-alert type="error">No quote was found for this job.
        <v-text-field label="Hourly Rate" hint="hourly rate to use when calculating adhoc work"
                      v-model.number="fallbackHourlyRate" class="hourly-rate" prefix="$" suffix="per hour"/>
      </v-alert>
    </div>
    <div>
      <span class="heading-h6 table-subheading">Labour</span>
      <table class="review-table">
        <thead>
        <tr>
          <th colspan="3" class="quoted heading">Quoted</th>
          <th colspan="3" class="previous heading">Previously Invoiced</th>
          <th colspan="3" class="this-month heading">Current Status</th>
          <th colspan="4" class="to-invoice heading">To Invoice</th>
        </tr>
        <tr>
          <th class="quoted">Task</th>
          <th class="quoted">$</th>
          <th class="quoted">Hours</th>

          <th class="previous">$</th>
          <th class="previous">Hours</th>
          <th class="previous">%</th>

          <th class="this-month">Hours this month</th>
          <th class="this-month">Hours to date</th>
          <th class="this-month">% of hours used</th>

          <th class="to-invoice">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small dense class="action-button" color="#eBb41E" v-bind="attrs" v-on="on" @click="copyActualLabour">
                  <v-icon small>mdi-arrow-right-thick</v-icon>
                </v-btn>
              </template>
              <span>Copy Actuals</span>
            </v-tooltip>
            %
          </th>
          <th class="to-invoice">$</th>
          <th class="to-invoice">Total after invoicing</th>
          <th class="to-invoice"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="task in labour.tasks" v-bind:key="task.UUID">
          <td class="quoted">{{ task.name }}
            <v-tooltip bottom v-if="task.notes && task.notes.length > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-help</v-icon>
              </template>
              <span v-html="task.notes.join('<br/>')"></span>
            </v-tooltip>

          </td>
          <td class="quoted">{{ task.quotedDollars | formatCurrency }}</td>
          <td class="quoted">{{ task.quotedTime | formatMinutes }}</td>

          <td class="previous">{{ task.previousInvoicedToDateDollars | formatCurrency }}</td>
          <td class="previous">{{ task.previousTimeToDate|formatMinutes }}</td>
          <td class="previous">{{ task.previouslyInvoicedPercent }}%</td>

          <td class="this-month">{{ task.thisMonthNewTime|formatMinutes }}</td>
          <td class="this-month">{{ task.totalTimeToDate|formatMinutes }}</td>
          <td class="this-month">{{ task.actualCompletionPercent }}%</td>

          <td class="editable">
            <v-text-field v-model.number="task.toInvoicePercent" suffix="%" class="right-input" filled
                          :rules="[isValidRange(task)]" @input="labour.recalculateTask('amount', task)"/>
          </td>
          <td class="editable">
            <v-text-field v-model.number="task.toInvoiceAmount" prefix="$" class="right-input" filled
                          @input="labour.recalculateTask('percent', task)"/>

          </td>
          <td class="to-invoice">{{ task.newInvoicedTotal|formatCurrency }}</td>

          <td class="to-invoice summary"><span v-html="task.summary"></span>
          </td>
        </tr>

        <!-- Labour Totals -->
        <tr class="totals">
          <td class="quoted">Total</td>
          <td class="quoted">{{ labour.quotedDollars|formatCurrency }}</td>
          <td class="quoted">{{ labour.quotedMinutes|formatMinutes }}</td>

          <td class="previous">{{ labour.previouslyInvoicedDollars|formatCurrency }}</td>
          <td class="previous">{{ labour.previouslyInvoicedMinutes|formatMinutes }}</td>
          <td class="previous">{{ labour.previouslyInvoicedPercent }}%</td>

          <td class="this-month">{{ labour.currentMinutes|formatMinutes }}</td>
          <td class="this-month">{{ labour.currentToDateMinutes|formatMinutes }}</td>
          <td class="this-month">{{ labour.currentToDatePercent }}%</td>

          <td class="to-invoice">{{ labour.toInvoicePercent }}%</td>
          <td class="to-invoice">{{ labour.toInvoiceDollars|formatCurrency }}</td>
          <td class="to-invoice">{{ labour.afterInvoicingDollars|formatCurrency }}</td>
          <td class="to-invoice summary"><span v-html="labour.overallSummary"></span></td>
        </tr>

        <tr>
          <td colspan="13" class="table-seperator">
            <span class="heading-h6 table-subheading">Costs</span>
          </td>
        </tr>

        <!-- Costs -->
        <tr>
          <th colspan="3" class="quoted heading">Quoted</th>
          <th colspan="3" class="previous heading">Previously Invoiced</th>
          <th colspan="3" class="this-month heading">Current Status</th>
          <th colspan="4" class="to-invoice heading">To Invoice</th>
        </tr>
        <tr>
          <th class="quoted"></th>
          <th class="quoted">$</th>
          <th class="quoted"></th>

          <th class="previous">$</th>
          <th class="previous"></th>
          <th class="previous">%</th>

          <th class="this-month">$ This month</th>
          <th class="this-month">$ to Date</th>
          <th class="this-month">% of costs used</th>

          <th class="to-invoice">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small dense class="action-button" color="#eBb41E" v-bind="attrs" v-on="on" @click="copyActualCosts">
                  <v-icon small>mdi-arrow-right-thick</v-icon>
                </v-btn>
              </template>
              <span>Copy Actuals</span>
            </v-tooltip>
            %
          </th>
          <th class="to-invoice">$</th>
          <th class="to-invoice">Total after invoicing</th>
          <th class="to-invoice"></th>
        </tr>
        <tr class="">
          <td class="quoted  mt-4"></td>
          <td class="quoted"> {{ costs.quoteDollars|formatCurrency }}</td>
          <td class="quoted"></td>

          <td class="previous">{{ costs.invoicedDollars|formatCurrency }}</td>
          <td class="previous"></td>
          <td class="previous">{{ costs.invoicedPercent }}%</td>

          <td class="this-month">{{ costs.currentDollars|formatCurrency() }}</td>
          <td class="this-month">{{ costs.currentToDateDollars|formatCurrency() }}</td>
          <td class="this-month">{{ costs.currentPercent }}%</td>

          <td class="editable">
            <v-text-field v-model.number="costs.toInvoicePercent" suffix="%" class="right-input" filled
                          @input="costs.recalculateCosts('amount')"/>
          </td>
          <td class="editable">
            <v-text-field v-model.number="costs.toInvoiceDollars" prefix="$" class="right-input" filled
                          @input="costs.recalculateCosts('percent')"/>

          </td>
          <td class="to-invoice"> {{ costs.newInvoicedTotal|formatCurrency() }}</td>
          <td class="to-invoice summary">
            <div v-for="(cost, idx) in this.costs.summary" v-bind:key="idx">
              {{ cost }}
            </div>
          </td>
        </tr>

        <!-- Grand Total -->
        <tr>
          <td colspan="13" class="table-seperator">
            <span class="heading-h6 table-subheading">Grand Total</span>
          </td>
        </tr>

        <tr>
          <th colspan="3" class="quoted heading">Quoted</th>
          <th colspan="3" class="previous heading">Previously Invoiced</th>
          <th colspan="3" class="this-month heading"></th>
          <th colspan="4" class="to-invoice heading">To Invoice</th>
        </tr>
        <tr>
          <th class="quoted"></th>
          <th class="quoted">$</th>
          <th class="quoted"></th>

          <th class="previous">$</th>
          <th class="previous"></th>
          <th class="previous">%</th>

          <th class="this-month"></th>
          <th class="this-month"></th>
          <th class="this-month"></th>

          <th class="to-invoice">%</th>
          <th class="to-invoice">$</th>
          <th class="to-invoice">Total after invoicing</th>
          <th class="to-invoice"></th>
        </tr>

        <tr class="">
          <td class="quoted  mt-4"></td>
          <td class="quoted"> {{ (costs.quoteDollars + labour.quotedDollars)|formatCurrency }}</td>
          <td class="quoted"></td>

          <td class="previous">{{ (costs.invoicedDollars + labour.previouslyInvoicedDollars)|formatCurrency }}</td>
          <td class="previous"></td>
          <td class="previous">{{ overallPreviousPercent }}%</td>

          <td class="this-month"></td>
          <td class="this-month"></td>
          <td class="this-month"></td>

          <td class="to-invoice"> {{ toInvoiceOverallPercent }}%</td>
          <td class="to-invoice"> {{ (costs.toInvoiceDollars + labour.toInvoiceDollars)|formatCurrency }}</td>
          <td class="to-invoice"> {{ (costs.newInvoicedTotal + labour.afterInvoicingDollars)|formatCurrency() }}</td>
          <td class="to-invoice"></td>
        </tr>
        </tbody>
      </table>
      <p>* Previous invoiced before {{ invoicePeriod }}.</p>

      <v-textarea v-model="comments" background-color="#eBb41E" color="white" filled label="Comments"
                  hint="Comments about this job to be used during invoicing"></v-textarea>

      <v-btn @click="save" color="primary" class="mt-5 pa-5">Save</v-btn>
    </div>

  </div>
</template>

<script>
import Labour from "@/lib/invoice/labour"
import Costs from "@/lib/invoice/costs"
import {toLocalString} from "@/lib/dateTimeUtilities"

export default {
  name: "JobsInvoiceReview",
  permissions: ["Read Job", "Read Quote", "Read Invoice", "Read Job Cost", "Read Job Task", "Prepare Invoices", "Read WIP"],
  props: ["jobId", "startDate", "endDate", "invoicePeriod"],
  data() {
    return {
      quoteFound: false, //marks if the quote was found on the job
      isReady: false,
      comments: "",
      labour: new Labour(this, this.invoicePeriod, this.startDate, this.endDate),
      costs: new Costs(this, this.invoicePeriod, this.startDate, this.endDate),
      job: null,
      quote: null,
      fallbackHourlyRate: 0
    }
  },
  computed: {
    overallPreviousPercent() {
      const totalInvoiced = this.costs.invoicedDollars + this.labour.previouslyInvoicedDollars
      const quoteTotal = this.costs.quoteDollars + this.labour.quotedDollars
      const value = Math.round(100 * totalInvoiced / quoteTotal)
      return Number.isFinite(value) ? value : "--"
    },
    toInvoiceOverallPercent() {
      const totalToInvoice = this.costs.newInvoicedTotal + this.labour.afterInvoicingDollars
      const quoteTotal = this.costs.quoteDollars + this.labour.quotedDollars
      const value = Math.round(100 * totalToInvoice / quoteTotal)
      return Number.isFinite(value) ? value : "--"
    },
  },
  methods: {
    copyActualCosts() {
      this.costs.copyActuals()
    },
    copyActualLabour() {
      this.labour.fallbackHourlyRate = this.fallbackHourlyRate // copy now so we can use the latest value
      const mode = this.quoteFound ? "quote" : "adhoc"
      this.labour.copyActuals(mode)
    },
    getLookbackQuery() {
      const endDate = new Date()
      const startDate = new Date()
      startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
      const start = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
      const end = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
      return {"from": start, "to": end, "detailed": "true"}
    },
    processJobResponse(response) {
      this.job = response.Job
      this.quoteFound = !!this.job.ApprovedQuoteID
      this.labour.processWfmJob(this.job)
      this.costs.processWfmJob(this.job)

      Promise.all([
        this.$WfmApi.get("time.api/job/" + this.jobId).then(response => {
          this.labour.processWfmTime(response)
        }),
        this.$WfmApi.get("invoice.api/job/" + this.jobId, this.getLookbackQuery()).then(response => {
          this.labour.processWfmInvoice(response)
          this.costs.processWfmInvoice(response)
        }),
        this.$WfmApi.get("job.api/costs/" + this.jobId).then(response => {
          this.costs.processWfmCosts(response)
        }),
        this.$WfmApi.get("quote.api/get/" + this.job.ApprovedQuoteUUID).then(response => {
          if (response.Quote) {
            this.quote = response.Quote
            this.costs.processWfmQuote(response)
            this.labour.processWfmQuote(response)
          }
        }),
      ]).then(() => {
        // Once everything is loaded from WFM

        // Load saved values
        const query = {job_id: this.jobId, month_str: this.invoicePeriod}
        this.$BwfApi.get("invoice/task-review", query).then(response => {
          this.labour.processBwf(response)
          this.costs.processBwf(response)
          this.comments = response.comments
          this.fallbackHourlyRate = response.hourly_rate
          this.isReady = true

          // Process derived values
          this.labour.recalculateTasks()
          this.labour.recalculateOverall()
          this.costs.recalculateCosts("percent")
          this.emitAll()
        })
      })

    },
    jobChanged() {
      this.isReady = false
      this.$WfmApi.get("job.api/get/" + this.jobId).then(response => this.processJobResponse(response))
    },
    isValidRange(task) {
      const value = task.toInvoicePercent
      let low = Math.floor(100 * task.previousInvoicedToDateDollars / task.quotedDollars)
      low = Number.isFinite(low) ? low : 0
      return (value >= low) ? true : `Must be greater than ${low}`
    },
    save() {
      const data = {
        job_id: this.job.ID,
        month_str: this.invoicePeriod,
        tasks: [],
        comments: this.comments,
        costs_percentage: this.costs.toInvoicePercent,
        costs_amount: this.costs.toInvoiceDollars,
        hourly_rate: this.fallbackHourlyRate
      }
      for (const task of this.labour.tasks) {
        data.tasks.push({
          task_id: task.UUID,
          percentage: task.toInvoicePercent,
          amount: task.toInvoiceAmount
        })
      }

      this.$BwfApi.post("invoice/task-review", data).then(() => {
        this.$notify.toast("Saved")
      })
      this.emitAll()
    },
    emitAll() {
      const all = {
        invoicePeriod: this.invoicePeriod,
        invoiceDataStartDate: this.startDate,
        invoiceDataEndDate: this.endDate,

        quotedLabourDollars: this.labour.quotedDollars,
        quotedLabourMinutes: this.labour.quotedMinutes,
        quotedCostsDollars: this.costs.quoteDollars,
        quotedOverallDollars: this.costs.quoteDollars + this.labour.quotedDollars,

        previouslyInvoicedLabourDollars: this.labour.previouslyInvoicedDollars,
        previouslyInvoicedLabourMinutes: this.labour.previouslyInvoicedMinutes,
        previouslyInvoicedLabourPercent: this.labour.previouslyInvoicedPercent,
        previouslyInvoicedCostsDollars: this.costs.invoicedDollars,
        previouslyInvoicedCostsPercent: this.costs.invoicedPercent,
        previouslyInvoicedOverallDollars: this.costs.invoicedDollars + this.labour.previouslyInvoicedDollars,
        previouslyInvoicedOverallPercent: this.overallPreviousPercent,

        currentLabourMinutes: this.labour.currentMinutes,
        currentLabourToDateMinutes: this.labour.currentToDateMinutes,
        currentLabourToDatePercent: this.labour.currentToDatePercent,
        currentCostsDollars: this.costs.currentDollars,
        currentCostsToDateDollars: this.costs.currentToDateDollars,
        currentCostsToDatePercent: this.costs.currentPercent,

        toInvoiceLabourPercent: this.labour.toInvoicePercent,
        toInvoiceLabourDollars: this.labour.toInvoiceDollars,
        toInvoiceCostsPercent: this.costs.toInvoicePercent,
        toInvoiceCostsDollars: this.costs.toInvoiceDollars,
        toInvoiceOverallPercent: this.toInvoiceOverallPercent,
        toInvoiceOverallDollars: Math.round(100 * (this.costs.toInvoiceDollars + this.labour.toInvoiceDollars)) / 100,

        afterInvoicingLabourDollars: this.labour.afterInvoicingDollars,
        afterInvoicingCostsDollars: Math.round(100 * (this.costs.toInvoiceDollars + this.labour.toInvoiceDollars)) / 100,
        afterInvoicingOverallDollars: Math.round(100 * (this.costs.newInvoicedTotal + this.labour.afterInvoicingDollars)) / 100,

        comments: this.comments,

        costDescriptions: this.costs.costDescriptions,
        tasks: this.labour.tasks,
        job: this.job,
        quote: this.quote,
        hourlyRate: this.fallbackHourlyRate
      }

      this.$emit("changed", all)
    }
  },
  mounted() {
    if (this.jobId) {
      this.jobChanged()
    }
  }
}

</script>

<style scoped>
.right-input {
  color: white;
  padding-top: 12px;
  min-width: 90px;
}

.right-input >>> input {
  text-align: right;
  color: white;
}

.review-table {
  border-collapse: collapse;
}

.review-table th {
  padding: 0 10px 0 10px;
  position: sticky;
  text-align: right;
  top: 65px;
  border-bottom: 1px #ccc solid;
}

.review-table th.heading {
  text-align: center;
  font-size: larger;

}

.review-table td {
  padding: 0 10px 0 10px;
  text-align: right;
  border-bottom: 1px #ccc solid;
}

.quoted {
  background: #3D550C;
  color: white
}

.previous {
  background: #81B622;
  color: white
}

.this-month {
  background: #787D12;
  color: white
}

.to-invoice {
  background: #59981A;
  color: white
}

.summary {
  min-width: 300px;
}

td.summary {
  text-align: left;
  white-space: nowrap;
}

.editable {
  background: #eBb41E;
}

.totals td {
  border-top: 3px solid #ccc;
  font-weight: bold;
}

.table-seperator {
  padding-bottom: 100px;
  text-align: left !important;
}

.table-subheading {
  font-weight: bolder;
  font-size: larger;
}

.action-button {
  height: 25px !important;
  width: 25px !important;
  padding: 0;
  margin-right: 5px;
  min-width: auto !important;
  float: left;
}

.hourly-rate {
  width: 200px
}
</style>

