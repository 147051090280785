<template>
  <div>
    <h1>Project Grouping</h1>
    API calls in progress: {{ apiCalls }}
    <v-btn @click="loadQuotes">Run</v-btn>
    <v-btn @click="download">Download</v-btn>
    <v-data-table :headers="headers" :items="filteredRows" :items-per-page="500">
    </v-data-table>
  </div>
</template>

<script>


import {toLocalString} from "@/lib/dateTimeUtilities"

export default {
  name: "ProjectGrouping",
  components: {},

  methods: {
    run() {
      const query = {"from": this.startDate, "to": this.endDate, "detailed": "true"}
      this.apiCalls++
      this.$WfmApi.get("job.api/list", query).then(response => {
        this.apiCalls--
        const jobs = this.$ensureArray(response.Jobs.Job)

        for (const i in jobs) {
          //for (let i = 0; i < 150; i++) {
          const row = {
            "jobNumber": "",
            "quoteNumber": "",
            "jobName": "",
            "jobSize": "Unknown",
            "jobHours": 0,
            "startDate": "",
            "year": "",
            "client": "",
            "clientGroup": "",
            "numberOfDisciplines": 0,

            "ArchitectureActual": 0,
            "CablesActual": 0,
            "ConsultingGeneralActual": 0,
            "ElectronicsActual": 0,
            "FirmwareActual": 0,
            "MechanicalActual": 0,
            "ProjectManagementActual": 0,
            "PrototypeActual": 0,
            "SoftwareActual": 0,
            "ArchitectureEstimated": 0,
            "CablesEstimated": 0,
            "ConsultingGeneralEstimated": 0,
            "ElectronicsEstimated": 0,
            "FirmwareEstimated": 0,
            "MechanicalEstimated": 0,
            "ProjectManagementEstimated": 0,
            "PrototypeEstimated": 0,
            "SoftwareEstimated": 0,

            "quotedMaterials": 0,
            "quotedLabor": 0,
            "quoteTotal": 0,

            "actualMaterials": 0,
            "actualLabor": 0,
            "actualTotal": 0,
            "invoicedTotal": 0,

            "projectDurationEstimate": 0,
            "projectDurationActual": 0,
            "projectDurationDelta": 0,

            "projectDollarsDelta": 0,
            "projectDollarsDeltaPercent": 0,

            "billingType": ''
          }

          const job = jobs[i]
          if (job.Type !== "Consulting") continue
          if (job.State !== "Completed") continue

          row.jobNumber = job.ID
          row.jobName = job.Name
          row.client = job.Client.Name
          row.startDate = job.StartDate.split("T")[0]
          row.year = row.startDate.substring(0, 4)
          if (parseInt(row.year) <2021) continue

          const start = new Date(job.StartDate)
          const end = new Date(job.DueDate)
          row.projectDurationEstimate = Math.round((end.getTime() - start.getTime()) / 86400000) // sec to days

          // Quoted type
          const billingTypeUUID = '9c483d6b-411b-4633-8edc-43e0535a2350'
          this.apiCalls++
          this.$WfmApi.get("job.api/get/" + job.ID + "/customfield", {})
              .then((res) => {
                this.apiCalls--
                row.billingType = this.processCustomField(res, billingTypeUUID)
              })

          //Tasks
          this.processTasks(row, job)

          // Client Group
          const clientGroupUUID = "2566c9d9-6653-4f36-baf3-5a1e5a76de5c"
          this.apiCalls++
          this.$WfmApi.get("client.api/get/" + job.Client.UUID + "/customfield", {})
              .then((res) => {
                this.apiCalls--
                row.clientGroup = this.processCustomField(res, clientGroupUUID)
              })

          // Costs
          this.apiCalls++
          this.$WfmApi.get("job.api/costs/" + job.ID).then(response => {
            this.apiCalls--
            if (response.Costs) {
              const costs = this.$ensureArray(response.Costs.Cost)
              costs.forEach(costEntry => {
                row.actualMaterials += Math.round(parseInt(costEntry.Quantity) * parseFloat(costEntry.UnitPrice))
              })
            }

            row.actualMaterials -= row.quotedMaterials // "actual" also includes quoted which is dumb and you can't filter them off
          })

          // Quote
          const quote = this.findQuote(job)
          if (!quote) continue

          row.quoteNumber = quote.ID
          row.quoteTotal = quote.Amount

          if (quote.Tasks) {
            const quoteTasks = this.$ensureArray(quote.Tasks.Task)
            for (let i in quoteTasks) {
              const task = quoteTasks[i]
              row.quotedLabor += parseFloat(task.Amount)
            }
          }

          if (quote.Costs) {
            const quoteCosts = this.$ensureArray(quote.Costs.Cost)
            for (let i in quoteCosts) {
              const cost = quoteCosts[i]
              row.quotedMaterials += Math.round(parseFloat(cost.Amount))
            }
          }


          // Invoice
          this.apiCalls++
          this.$WfmApi.get("invoice.api/job/" + job.ID, query).then(response => {
            this.apiCalls--
            if (response.Invoices) {
              const invoices = this.$ensureArray(response.Invoices.Invoice)
              let last_invoice_date = 0
              for (let i in invoices) {
                const invoice = invoices[i]
                if (invoice.Status === "Approved" || invoice.Status === "Paid") {
                  row.invoicedTotal += Math.round(parseFloat(invoice.Amount))
                  const d = new Date(invoice.Date)
                  last_invoice_date = (d.getTime() > last_invoice_date) ? d.getTime() : last_invoice_date
                }
              }
              row.projectDurationActual = Math.round((last_invoice_date - start.getTime()) / 86400000)
              row.projectDurationDelta = row.projectDurationActual - row.projectDurationEstimate
              row.actualTotal = row.actualLabor + row.actualMaterials
              row.projectDollarsDelta = Math.round(row.actualTotal - row.invoicedTotal)
              row.projectDollarsDeltaPercent = Math.round(100 * row.projectDollarsDelta / row.invoicedTotal)
            }
          })
          if (row.jobSize === "Unknown") continue // probably just an empty job
          this.rows.push(row)
        }

      })
    },
    processCustomField(res, uuid) {
      res.CustomFields = res.CustomFields || {} // make sure there is a default
      const defaultSettings = this.$ensureArray(res.CustomFields.CustomField)
      for (let i = 0; i < defaultSettings.length; i++) {
        if (uuid === defaultSettings[i].UUID) {
          return defaultSettings[i].Text || defaultSettings[i].Decimal || defaultSettings[i].Number ||
              defaultSettings[i].Boolean ||
              toLocalString(new Date(defaultSettings[i].Date)).substr(0, 10) || ""
        }
      }
      return ""
    },
    processTasks(row, job) {
      if (job.Tasks) {
        //   let estimateTotal = 0
        let actualTotal = 0
        const disciplines = new Set()
        const tasks = this.$ensureArray(job.Tasks.Task)
        for (let i in tasks) {
          const task = tasks[i]
          // estimateTotal += Math.round(parseInt(task.EstimatedMinutes)/60)
          actualTotal += Math.round(parseInt(task.ActualMinutes) / 60)

          if (task.TaskUUID === "9e52b003-4c7b-4b3f-a844-ea8aa0dad08d") {
            row.ArchitectureActual += Math.round(parseInt(task.EstimatedMinutes) / 60)
            row.ArchitectureEstimated += Math.round(parseInt(task.ActualMinutes) / 60)
            disciplines.add("Architecture")
          } else if (task.TaskUUID === "21220d1e-6c4e-4fbb-80e7-9162b11c1320") {
            row.CablesActual += Math.round(parseInt(task.EstimatedMinutes) / 60)
            row.CablesEstimated += Math.round(parseInt(task.ActualMinutes) / 60)
            disciplines.add("Cables")
          } else if (task.TaskUUID === "b559fd93-6090-4647-9730-6b0c07764ac4") {
            row.ConsultingGeneralActual += Math.round(parseInt(task.EstimatedMinutes) / 60)
            row.ConsultingGeneralEstimated += Math.round(parseInt(task.ActualMinutes) / 60)
            disciplines.add("Consulting General")
          } else if (task.TaskUUID === "036c2019-1b2c-4ff7-88b7-048a4b23b1b1") {
            row.ElectronicsActual += Math.round(parseInt(task.EstimatedMinutes) / 60)
            row.ElectronicsEstimated += Math.round(parseInt(task.ActualMinutes) / 60)
            disciplines.add("Electronics")
          } else if (task.TaskUUID === "fbffcd16-fcc1-4ae4-9eb7-919a428400e3") {
            row.FirmwareActual += Math.round(parseInt(task.EstimatedMinutes) / 60)
            row.FirmwareEstimated += Math.round(parseInt(task.ActualMinutes) / 60)
            disciplines.add("Firmware")
          } else if (task.TaskUUID === "b2b7eaec-7ccc-4c08-a90d-5a43c9ff8997") {
            row.MechanicalActual += Math.round(parseInt(task.EstimatedMinutes) / 60)
            row.MechanicalEstimated += Math.round(parseInt(task.ActualMinutes) / 60)
            disciplines.add("Mechanical")
          } else if (task.TaskUUID === "d4cb43a6-c2ff-4751-b785-26efbf8ef29b") {
            row.ProjectManagementActual += Math.round(parseInt(task.EstimatedMinutes) / 60)
            row.ProjectManagementEstimated += Math.round(parseInt(task.ActualMinutes) / 60)
            disciplines.add("Project Management")
          } else if (task.TaskUUID === "2a2b01cd-0426-4ac6-97b7-667205d326df") {
            row.PrototypeActual += Math.round(parseInt(task.EstimatedMinutes) / 60)
            row.PrototypeEstimated += Math.round(parseInt(task.ActualMinutes) / 60)
            disciplines.add("Prototype")
          } else if (task.TaskUUID === "daa85700-8d18-4a8b-b690-ebfab463d388") {
            row.SoftwareActual += Math.round(parseInt(task.EstimatedMinutes) / 60)
            row.SoftwareEstimated += Math.round(parseInt(task.ActualMinutes) / 60)
            disciplines.add("Software")
          }
        }

        row.numberOfDisciplines = disciplines.size
        row.actualLabor = actualTotal * 150
        row.jobHours = actualTotal
        if (actualTotal < 200) row.jobSize = "Small"
        else if (actualTotal < 700) row.jobSize = "Medium"
        else row.jobSize = "Large"
      }
    },
    loadQuotes() {
      const query = {"from": this.startDate, "to": this.endDate, "detailed": "true"}
      this.apiCalls++
      this.$WfmApi.get("quote.api/list", query).then(response => {
        this.apiCalls--
        if (response.Quotes) {
          this.quotes = this.$ensureArray(response.Quotes.Quote)
          this.run()
        }
      })
    },
    findQuote(job) {
      let foundQuoteID = ""
      let foundQuote = null
      this.quotes.forEach(function (quote) {
        if (quote.Name === job.Name && quote.ID > foundQuoteID && quote.State === "Accepted") {
          foundQuoteID = parseInt(quote.ID)
          foundQuote = quote
        }
      })
      return foundQuote
    },
    download() {
      const filename = "Export Log"
      const bom = "\uFEFF" // BOM header helps with Unicode compatibility (eg. ºC)
      const text = this.makeCsv()
      const element = document.createElement("a")
      element.setAttribute("href", "data:text/csv;charset=utf-8," + bom + encodeURIComponent(text))
      element.setAttribute("download", filename)
      element.style.display = "none"
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
    makeCsv() {
      return [
        this.headers.map((item) => {//Headers
          return item.text
        }),
        ...this.filteredRows.map(item => { // Data
          const row = []
          for (const idx in this.headers) {
            const head = this.headers[idx]
            const val = item[head.value] ? item[head.value].toString() : "0"
            row.push(val.replaceAll(",", "")) // remove comma to make csv work
          }
          return row
        })].map(e => e.join(",")).join("\n")
    },
  },
  computed: {
    filteredRows: function () {
      return this.rows.filter(item => item.billingType ==='Quoted (Fixed)'  )
    }
  },
  mounted() {
    const endDate = new Date()
     const startDate = new Date()
    startDate.setFullYear(startDate.getFullYear() - this.$WfmApi.lookback)
    this.startDate = toLocalString(startDate).substr(0, 10).replace(/-/g, "")
    this.endDate = toLocalString(endDate).substr(0, 10).replace(/-/g, "")
  },
  data() {
    return {
      quotes: [],
      startDate: null,
      endDate: null,
      apiCalls: 0,
      headers: [
        {text: "Job Number", value: "jobNumber"},
        {text: "Quote Number", value: "quoteNumber"},
        {text: "Client", value: "client"},
        {text: "Job Name", value: "jobName"},
        {text: "Client Group", value: "clientGroup"},
        {text: "Job Size", value: "jobSize"},
        {text: "Job Hours", value: "jobHours"},
        //{text: "Start Date", value: "startDate"},
        {text: "Year", value: "year"},
        {text: "Number of Disciplines", value: "numberOfDisciplines"},

        {text: "Architecture Estimate (hrs)", value: "ArchitectureEstimated"},
        {text: "Architecture Actual (hrs)", value: "ArchitectureActual"},

        {text: "Cables Estimate (hrs)", value: "CablesEstimated"},
        {text: "Cables Actual (hrs)", value: "CablesActual"},

        {text: "Consulting General Estimate (hrs)", value: "ConsultingGeneralEstimated"},
        {text: "Consulting General Actual (hrs)", value: "ConsultingGeneralActual"},

        {text: "Electronics Estimate (hrs)", value: "ElectronicsEstimated"},
        {text: "Electronics Actual (hrs)", value: "ElectronicsActual"},

        {text: "Firmware Estimate (hrs)", value: "FirmwareEstimated"},
        {text: "Firmware Actual (hrs)", value: "FirmwareActual"},

        {text: "Mechanical Estimate (hrs)", value: "MechanicalEstimated"},
        {text: "Mechanical Actual (hrs)", value: "MechanicalActual"},

        {text: "Project Management Estimate (hrs)", value: "ProjectManagementEstimated"},
        {text: "Project Management Actual (hrs)", value: "ProjectManagementActual"},

        {text: "Prototype Estimate (hrs)", value: "PrototypeEstimated"},
        {text: "Prototype Actual (hrs)", value: "PrototypeActual"},

        {text: "Software Actual (hrs)", value: "SoftwareActual"},
        {text: "Software Estimate (hrs)", value: "SoftwareEstimated"},

        //{text: "Project Duration Estimate (days)", value: "projectDurationEstimate"},
        //{text: "Project Duration Actual (days)", value: "projectDurationActual"},
        //{text: "Project Duration Delta (days)", value: "projectDurationDelta"},

        {text: "Quoted Materials ($)", value: "quotedMaterials"},
        {text: "Actual Materials ($)", value: "actualMaterials"},

        {text: "Quoted Labour ($)", value: "quotedLabor"},
        {text: "Actual Labour ($)", value: "actualLabor"},

        {text: "Actual Total ($)", value: "actualTotal"},
        {text: "Invoiced ($)", value: "invoicedTotal"},

        {text: "Overage ($)", value: "projectDollarsDelta"},
        {text: "Overage (%)", value: "projectDollarsDeltaPercent"},
      ],
      rows: []
    }
  },
}

</script>

<style scoped>

</style>