<template>
  <BfwComponent :isReady="isReady" :title="title" :showTitle="showTitle" :editable="editable" :hideCard="false"
                loaderType="list-item">

    <div class="chart-area">
      <v-chart class="chart" :option="chartOptions" autoresize/>
    </div>

  </BfwComponent>
</template>

<script>
import BfwComponent from "../BwfComponent"
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {BarChart} from "echarts/charts"
import {
  LegendComponent,
  GridComponent,
} from "echarts/components"
import VChart from "vue-echarts"
import {defineComponent} from "vue"

use([
  CanvasRenderer,
  BarChart,
  LegendComponent,
  GridComponent,
])
export default defineComponent({
  name: "JobMonthProgress",
  props: ["jobId", "monthStr"],
  permissions: ["Read Job", "Read Time", "Read Capacity Plan", "Read Services", "Read Staff Plan"],
  components: {
    VChart,
    BfwComponent
  },
  computed: {
    chartOptions: function () {
      const labels = []
      const plannedData = []
      const usedData = []
      const scheduledData = []

      for (const service of this.availableServices) {
        labels.push(service.name)
        plannedData.push(this.capacityData[service.service_id])
        usedData.push(this.calculateUsedTime(service.uuid))
        scheduledData.push(this.calculateScheduledTime(service.service_id))
      }

      return {
        legend: {},
        xAxis: {
          type: "category",
          data: labels,
          axisLabel: {
            interval: 0,
            rotate: 30
          }
        },
        yAxis: {
          type: "value",
        },
        splitLine: {
          show: true,
        },
        splitArea: {
          show: true
        },
        series: [
          {
            name: "Planned",
            type: "bar",
            data: plannedData,
            stack: "stack0",
            itemStyle: {
              color: "#FF8138"
            },
          },
          {
            name: "Actual",
            type: "bar",
            data: usedData,
            stack: "stack1",
            itemStyle: {
              color: "#92C6FF"
            }
          },
          {
            name: "Scheduled",
            type: "bar",
            data: scheduledData,
            stack: "stack1",
            itemStyle: {
              color: "#99ee99"
            }
          },
        ]
      }
    },
  },
  mounted() {
    if (this.jobId) {
      this.loadAll()
    }
  },
  methods: {
    loadAll() {
      Promise.all([
        this.$WfmApi.get("job.api/get/" + this.jobId).then(response => this.processJobResponse(response)),
        this.$WfmApi.get("time.api/job/" + this.jobId).then(response => this.processTimeResponse(response)),
        this.$BwfApi.get("capacity-plan/job", {job_id: this.jobId}).then((response) => this.processCapacityResponse(response)),
        this.$BwfApi.get("services", {enabled: true}).then(response => this.processServices(response)),
        this.$BwfApi.get("staff-plan/job", {job_id: this.jobId}).then(response => this.processPlanLoad(response))
      ]).then(() => {
        this.availableServices = this.findAvailableServices()
        this.isReady = true
      })
    },
    processServices(response) {
      this.services = response
    },
    processJobResponse(response) {
      this.job = response.Job
    },
    processTimeResponse(response) {
      if (response.Times) {
        this.timeEntries = this.$ensureArray(response.Times.Time)
      }
    },
    processPlanLoad(response) {
      this.plan = response
    },
    calculateScheduledTime() { //serviceId
      let total = 0
      // for (const item of this.plan) {
      //   const monthStr = item.year.toString() + "-" + item.month.toString().padStart(2, "0")
      //
      //  // console.log(monthStr ,this.monthStr, item.service_id, serviceId)
      //   if (monthStr === this.monthStr && item.service_id === serviceId) {
      //     total += item.allocated_hours
      //      //       console.log(item)
      //   }
      // }
      return total
    },
    calculateUsedTime() {//serviceUUID
      let total = 0
      // for (const entry of this.timeEntries) {
      //   const taskUuid = this.findTaskTypeUuid(entry.Task.UUID)
      //   const service = this.findService(taskUuid)
      //   if (service.uuid === serviceUUID) {
      //     const monthStr = entry.Date.substr(0, 7)
      //     if (monthStr === this.monthStr) {
      //       total += parseInt(entry.Minutes)
      //     }
      //   }
      // }
      return Math.round(total / 60)
    },
    findTaskTypeUuid(taskUUID) {
      if (!("Tasks" in this.job)) return false

      const tasks = this.$ensureArray(this.job.Tasks.Task)
      for (const task of tasks) {
        if (task.UUID === taskUUID) {
          return task.TaskUUID
        }
      }
      return false
    },
    findService(taskTypeUUID) {
      for (const service of this.services) {
        if (service.uuid === taskTypeUUID) {
          return service
        }
      }
      return false
    },
    processCapacityResponse(response) {
      console.log(response)
      // if (this.monthStr in response) {
      //   this.capacityData = response[this.monthStr]
      // }
    },
    findAvailableServices() {
      const tasks = new Set()
      if (this.job && this.job.Tasks) {
        const tsks = this.$ensureArray(this.job.Tasks.Task)
        for (const task of tsks) {
          tasks.add(task.TaskUUID)
        }
      }
      const availableServices = []
      if (this.services) {
        for (const service of this.services) {
          if (tasks.has(service.uuid)) {
            availableServices.push(service)
          }
        }
      }
      return availableServices
    },
  },
  watch: {
    jobId: function () {
      if (this.jobId) {
        this.isReady = false
        this.loadAll()
      } else {
        this.isReady = false
      }
    }
  },
  data() {
    return {
      title: "Job Plan",
      showTitle: true,
      editable: false,
      isReady: false,
      valid: true,
      job: {},
      availableServices: [],
      capacityData: {},
      timeEntries: [],
      services: [],
      data: []
    }
  },
})

</script>

<style scoped>
.chart-area {
  display: flex;
}

.chart {
  height: 300px;
}
</style>