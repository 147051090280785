<template>
  <v-card :class="overdueClass+' quote ma-5'">
    <v-card-title>
      <CornerRibbon text="Prospect" v-if="lead.is_prospect"></CornerRibbon>
      {{ lead.title }}
    </v-card-title>
    <v-card-text>
      <MoreText :max-lines="3" minHeight="88px">{{ lead.description }}</MoreText>
      <div><span class="strong">Owner:</span> {{ lead.owner ? lead.owner.text : "" }}</div>
      <div><span class="strong">Follow up due:</span> {{ lead.follow_up_date }}</div>
      <div>
        <span class="strong">Lead expiry:</span> {{ lead.expiry ? lead.expiry : "" }}
        <div v-if="isExpired" class="inline-block">
          <v-icon color="red" class="mr-1">mdi-flag</v-icon>
          <v-btn x-small rounded color="primary" class="mr-1" @click="changeLeadState(lead.lead_id, 'won')">
            Won            <v-icon small>mdi-thumb-up</v-icon>
          </v-btn>
          <v-btn x-small rounded color="error" class="mr-1" @click="changeLeadState(lead.lead_id, 'lost')">
          Lost            <v-icon small>mdi-thumb-down</v-icon>
          </v-btn>
        </div>
      </div>
      <div><span class=" strong
          ">Client:</span> {{ lead.client ? lead.client.Name : "" }}
        </div>
        <div><span class="strong">Contact:</span> {{ lead.contact ? lead.contact.Name : "" }}
          <v-btn text x-small dense @click="showPopup=true">
            <v-icon>mdi-window-restore</v-icon>
          </v-btn>
        </div>

        <div v-if="['quoted', 'won', 'lost'].includes(lead.stage)">
          <span class="strong">Probability of acceptance:</span> {{ lead.acceptanceProbability ? lead.acceptanceProbability.text : "Unknown" }}
        </div>

        <div v-if="['quoted', 'won', 'lost'].includes(lead.stage)"><span class="strong">Quote:</span> {{ lead.quote ? lead.quote.ID : "" }}
          <v-btn href="https://app.workflowmax2.com/organizations/9c483c99-b3b2-4625-bdb2-081145336d38/quotes/active_quotes" text x-small dense target="_blank">
            <v-icon>mdi-link</v-icon>
          </v-btn>
        </div>

        <v-btn @click="$emit('selected', lead)" class="full-width mt-3" color="primary">Edit
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-expansion-panels flat>
          <v-expansion-panel :class="overdueClass">
            <v-expansion-panel-header>More</v-expansion-panel-header>
            <v-expansion-panel-content class="more-expanded">
              <div><span class="strong">Created:</span> {{ lead.created ? lead.created : "" }}</div>
              <div><span class="strong">Region:</span> {{ lead.client.region }}</div>
<!--              <div><span class="strong">Business Type:</span> {{ lead.business_type ? lead.business_type : "" }}</div>-->
              <div class="strong">Sales Notes:</div>
              <div> {{ lead.sales_notes }}</div>
              <div>
                <div class="strong">Next Action:</div>
                {{ lead.follow_up_action }}
              </div>
              <div><span class="strong">Due:</span> {{ lead.follow_up_date }}</div>

              <v-list dense title="Action Steps" :class="overdueClass">
                <v-list-item v-for="(step, idx) in lead.steps" v-bind:key="idx">
                  <v-list-item-icon>
                    <v-icon v-if="step.complete === 1">mdi-check</v-icon>
                    <v-icon v-if="step.complete === 0">mdi-close</v-icon>
                    <v-icon v-if="step.complete === -1">mdi-minus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{ step.name }}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

    </v-card-text>

    <ClientPopup v-model="showPopup" v-if="lead.client && lead.contact"
                 :selectedClient="lead.client" :selectedContact="lead.contact"></ClientPopup>
  </v-card>
</template>

<script>

import CornerRibbon from "@/components/toolkit/CornerRibbon.vue"
import ClientPopup from "@/components/clients/ClientPopup.vue"
import MoreText from "@/components/toolkit/MoreText.vue"

export default {
  components: {MoreText, ClientPopup, CornerRibbon},
  permissions: ["Read Leads"],
  props: {
    lead: {
      type: Object,
      required: true,
    }
  },
  methods: {
    changeLeadState(leadId, newStage) {
      this.$BwfApi.post("leads/"+leadId+"/stage", {stage:newStage}).then(() => {
        this.$notify.toast("Saved")
        this.$emit("input")
      })
    }
  },
  computed: {
    isExpired: function () {
      const now = new Date()
      return new Date(this.lead.expiry) < now
    },
    overdueClass: function () {
      const now = new Date()
      if (new Date(this.lead.follow_up_date) < now) {
        return "highlight-card"
      } else {
        return ""
      }
    }
  },
  data() {
    return {
      showPopup: false,
    }
  },
}

</script>

<style scoped>
.full-width {
  width: 100%;
}

.inline-block {
  display: inline-block;
}

.quote {
  width: 350px;
  display: inline-block;
}

.strong {
  font-weight: bold;
}

.highlight-card {
  background: pink !important;
}

.more-expanded >>> div {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.expanded {
  height: auto !important;
}
</style>